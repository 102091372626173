<template>
  <div id="chart" class="container" ref="containerChart">
    <VueApexCharts
      type="area"
      :options="chartOptions"
      :series="series"
      :height="height"
      :key="key + updateGraph"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapState, mapGetters } from 'vuex';
import { toFixedTwoDigitsMax } from '@/utils/misc';

export default {
  name: 'ChartTrendLive',
  components: {
    VueApexCharts,
  },
  props: {
    teamTypeSelected: {
      type: String,
      default: 'homeTeam',
    },
    subcategorySelected: {
      type: Object,
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
    teamId: {
      type: Number,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      height: 0,
      updateGraph: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.setDimensions();
    }, 500);
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('nextGeneral', [
      'idAssetSelected',
      'menuOptionActive',
      'showLiveData',
      'homeTeamPlays',
      'visitingTeamPlays',
      'totalHomeTeamFinishedGames',
      'totalVisitingTeamFinishedGames',
      'gameForAnalysis',
    ]),
    ...mapGetters('nextGeneral', [
      'homeTeam',
      'homeTeamStatisticsType',
      'visitingTeamStatisticsType',
      'showPostMatchData',
    ]),
    chartOptions() {
      return {
        chart: {
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: this.isAccumulatedType,
          enabledOnSeries: [0, 1],
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5],
        },
        legend: {
          tooltipHoverFormatter: (val, opts) => {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
          },
        },
        markers: {
          size: 1,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: this.xaxisCategories,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.7,
            stops: [0, 100, 100],
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: '#000000',
            },
          },
        ],
        tooltip: {
          y: [
            {
              title: {
                formatter: val => (this.showPostMatchData ? `${val} :` : `${val} (promedio)`),
              },
              formatter: val => (this.showPostMatchData ? Number(val) : val),
            },
            {
              title: {
                formatter: val => `${val} (actual)`,
              },
            },
            {
              title: {
                formatter: val => val,
              },
            },
          ],
        },
        grid: {
          borderColor: '#fff',
        },
        responsive: [
          {
            breakpoint: 800,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                position: 'bottom',
              },
              height: 350,
              width: 350,
            },
          },
          {
            breakpoint: 350,
            options: {
              height: 100,
              width: 100,
            },
          },
        ],
      };
    },
    series() {
      const series = [
        {
          name: this.typeName,
          data: this.dataStats,
        },
      ];
      if (this.showLiveData) {
        series.push({
          name: 'LIVE',
          data: this.dataLive,
        });
      }
      return series;
    },
    typeName() {
      return this.types.find(type => type.id === this.typeId).name;
    },
    dataStats() {
      if (this.isAccumulatedType) {
        return [
          0,
          ...this.subcategorySelected[this.teamTypeSelected][
            this[`${this.teamTypeSelected}StatisticsType`]
          ].avgPostGameTrendLive.accumulated.map(sectionTotal => toFixedTwoDigitsMax(sectionTotal)),
        ];
      }
      return this.subcategorySelected[this.teamTypeSelected][
        this[`${this.teamTypeSelected}StatisticsType`]
      ].avgPostGameTrendLive.sections.reduce(
        (accumulator, sectionAvg) => {
          accumulator.push(toFixedTwoDigitsMax(sectionAvg));
          return accumulator;
        },
        [0],
      );
    },
    dataLive() {
      if (this.isAccumulatedType) {
        return [
          0,
          ...this.subcategorySelected[this.teamTypeSelected][
            this[`${this.teamTypeSelected}StatisticsType`]
          ].totalsLiveTrendLive.accumulated.slice(
            0,
            this.subcategorySelected[this.teamTypeSelected].maximumLiveSection,
          ),
        ];
      }
      return this.subcategorySelected[this.teamTypeSelected][
        this[`${this.teamTypeSelected}StatisticsType`]
      ].totalsLiveTrendLive.sections
        .slice(0, this.subcategorySelected[this.teamTypeSelected].maximumLiveSection)
        .reduce(
          (accumulator, current) => {
            accumulator.push(current);
            return accumulator;
          },
          [0],
        );
    },
    xaxisCategories() {
      if (this.isAccumulatedType) {
        return ["0'", "15'", "30'", "45'", "60'", "75'", "90'"];
      }
      return ["0'", "15'", "30'", "45'", "60'", "75'", "90'"];
    },
    isAccumulatedType() {
      return this.typeId === 1;
    },
    factorHeight() {
      return this.displayWidth - (this.displayWidth % 50);
    },
    key() {
      return this.showLiveData ? 1 : 2;
    },
  },
  watch: {
    menuOptionActive() {
      setTimeout(() => {
        this.setDimensions();
      }, 500);
      this.updateGraph += 1;
    },
    idAssetSelected() {
      if (this.idAssetSelected == 7) {
        this.updateGraph += 1;
      }
    },
    displayWidth() {
      this.setDimensions();
    },
    series() {
      this.setDimensions();
    },
  },
  methods: {
    setDimensions() {
      if (this.displayWidth < 300) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.9 + 'px';
        this.height = this.factorHeight * 0.6;
      } else if (this.displayWidth >= 300 && this.displayWidth < 400) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.7 + 'px';
        this.height = this.factorHeight * 0.6;
      } else if (this.displayWidth >= 400 && this.displayWidth < 530) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.8 + 'px';
        this.height = this.factorHeight * 0.6;
      } else if (this.displayWidth >= 530 && this.displayWidth < 600) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.75 + 'px';
        this.height = this.factorHeight * 0.5;
      } else if (this.displayWidth >= 600 && this.displayWidth < 700) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.6 + 'px';
        this.height = this.factorHeight * 0.5;
      } else if (this.displayWidth >= 700 && this.displayWidth < 769) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.6 + 'px';
        this.height = this.factorHeight * 0.5;
      } else if (this.displayWidth >= 769 && this.displayWidth < 900) {
        this.$refs.containerChart.style.width = this.displayWidth * 0.7 + 'px';
        this.height = this.factorHeight * 0.4;
      } else if (this.displayWidth >= 900) {
        this.$refs.containerChart.style.width = 600 + 'px';
        this.height = 400;
      }
      if (this.idAssetSelected == 7) {
        setTimeout(() => {
          var existElement = document.getElementsByClassName('apexcharts-svg');
          if (existElement.length > 0) {
            document.getElementsByClassName('apexcharts-svg')[0].style.width =
              parseInt(this.$refs.containerChart.style.width) + 50 + 'px';
          }
        }, 800);
      }
    },
  },
};
</script>

<style scoped>
#chart {
  margin: 10px auto;
}
.apexcharts-menu-icon {
  display: none;
}
</style>
