<template>
  <div class="container container-trend-live">
    <div class="columns has-text-centered">
      <div class="column is-one-third team">
        <div class="block">
          <BField>
            <BRadioButton
              v-model="teamId"
              :native-value="homeTeam.team_id"
              type="is-light"
              :class="{ 'live-radio-selected': teamId === homeTeam.team_id }"
              @change.native="onChangeTeam"
            >
              <img :src="homeTeam.logo" alt="Local Team Logo" />
              <span>{{ homeTeam.acronym }}</span>
            </BRadioButton>
            <BRadioButton
              v-model="teamId"
              :native-value="visitingTeam.team_id"
              type="is-light"
              :class="{ 'live-radio-selected': teamId === visitingTeam.team_id }"
              @change.native="onChangeTeam"
            >
              <img :src="visitingTeam.logo" alt="Visit Team Logo" />
              <span>{{ visitingTeam.acronym }}</span>
            </BRadioButton>
          </BField>
        </div>
      </div>
      <div class="column is-one-third category">
        <div class="block">
          <BField>
            <BSelect placeholder="Seleccionar" v-model="subcategorySelected" @change.native="onChangeTipoVista">
              <option v-for="(subcategory, name, index) in statisticData" :value="subcategory" :key="index">
                {{ subcategory.names['0-0'] }}
              </option>
            </BSelect>
          </BField>
        </div>
      </div>
      <div class="column is-one-third type">
        <div class="block">
          <BRadio
            v-for="option in types"
            v-model="typeId"
            name="type"
            :native-value="option.id"
            :key="option.id"
            @change.native="onChangeTipoDeCalculo"
          >
            {{ option.name }}
          </BRadio>
        </div>
      </div>
    </div>
    <ChartTrendLive
      :typeId="typeId"
      :teamTypeSelected="teamTypeSelected"
      :subcategorySelected="subcategorySelected"
      :teamId="teamId"
      :categoryId="categoryId"
      :types="types"
      :key="typeId"
    >
    </ChartTrendLive>
  </div>
</template>

<script>
import ChartTrendLive from '@/components/Elements/NextMatches/ChartTrendLive';
import { mapState, mapGetters } from 'vuex';
import { BField } from 'buefy/dist/esm/field';
import { BRadio, BRadioButton } from 'buefy/dist/esm/radio';
import { BSelect } from 'buefy/dist/esm/select';

import {
  clickSwitchTendenciaLiveEquipo,
  clickSelectTendenciaLiveSelectorEstadistica,
  clickSelectTendenciaLiveSelectorTipoVista,
} from '@/utils/analytics';

export default {
  components: {
    ChartTrendLive,
    BField,
    BRadio,
    BRadioButton,
    BSelect,
  },
  data() {
    return {
      typeId: 1,
      teamId: 0,
      categoryId: 1,
      subcategorySelected: null,
      types: [
        { id: 1, name: 'Acumulado' },
        { id: 2, name: 'Por Períodos' },
      ],
    };
  },
  mounted() {
    this.teamId = this.homeTeam.team_id;
  },
  watch: {
    statisticData: {
      immediate: true,
      handler(newValue) {
        this.subcategorySelected = Object.values(newValue)[0];
      },
    },
  },
  computed: {
    ...mapState('nextGeneral', ['gameForAnalysis']),
    ...mapGetters('nextGeneral', ['game', 'statisticData', 'homeTeam', 'visitingTeam', 'gameId', 'isLive']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('general', ['getPreviewSectionAnalytics']),
    // categories() {
    //   return this.stats.map(stat => {
    //     return {
    //       id: stat.id,
    //       name: stat.name,
    //     };
    //   });
    // },
    teamTypeSelected() {
      return this.teamId === this.gameForAnalysis.visiting_team ? 'visitingTeam' : 'homeTeam';
    },
  },
  methods: {
    onChangeTeam(event) {
      clickSwitchTendenciaLiveEquipo(
        this.gameId,
        this.isLive,
        event.target.value == this.homeTeam.team_id ? 'Local' : 'Visitante',
        this.getPremiumAccount,
        this.getIsGolstats,
      );
    },
    onChangeTipoVista(event) {
      let selected = event.target.selectedIndex;
      if (selected >= 0) {
        let selectedText = event.target[selected].text;
        clickSelectTendenciaLiveSelectorEstadistica(
          this.gameId,
          this.isLive,
          selectedText,
          this.getPremiumAccount,
          this.getPreviewSectionAnalytics,
          this.getIsGolstats,
        );
      }
    },
    onChangeTipoDeCalculo(event) {
      let selected = event.target.value;
      if (selected > 0) {
        clickSelectTendenciaLiveSelectorTipoVista(
          this.gameId,
          this.isLive,
          this.types.find(t => t.id == selected).name,
          this.getPremiumAccount,
          this.getPreviewSectionAnalytics,
          this.getIsGolstats,
        );
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 25px;
  height: 25px;
  vertical-align: text-bottom;
}
span {
  vertical-align: super;
  padding-left: 5px;
  font-size: 14px;
  font-weight: bold;
}
.has-text-centered {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
@media screen and (max-width: 768px) {
  .has-text-centered {
    margin-top: -5px !important;
    display: flex;
    flex-direction: column;
  }
  .team,
  .category,
  .type {
    align-self: center;
    margin: 5px 0 5px 0 !important;
    padding: 5px 0 5px 0 !important;
  }
  .control {
    width: 90% !important;
    margin-left: 5% !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 530px) {
  .has-text-centered {
    width: 90% !important;
    margin-left: 5% !important;
  }
}
.type {
  margin-top: 5px;
}
.container-trend-live {
  margin: 10px 0 0 0 !important;
  padding-top: 5px !important;
}
</style>

<style lang="scss">
.container-trend-live {
  & .live-radio-selected {
    & label.button {
      background-color: #e6eefc !important;
    }
  }
}
</style>
